<template>
  <EntryContent :status="pageStatus" v-show="modalStatus != 'modal-open'">
    <template #h1>
      Projects: Websites
    </template>
    <template #h2>
      <span class="br">Websites that I've designed and built using</span> 
      Laravel, Vue.JS, and WordPress<span class="blink">&marker;</span>
    </template>
  </EntryContent>
  <Draw :pageStatus="pageStatus" endpoint="projects/websites" />
</template>

<script>
import EntryContent from '@/components/EntryContent.vue'
import Draw from '@/components/Draw.vue'

export default {
  name: "Websites",

  components: {
    EntryContent,
    Draw
  },

  data: () => ({
    pageTitle: 'Websites | ',
    pageStatus: '',
    modalStatus: ''
  }),

  created () {
    this.openPage()
    document.title = this.pageTitle + this.$store.getters.siteTitle
  },

  computed: {
    getPageStatus () {
      return this.$store.getters.pageStatus
    },
    getModalStatus () {
        return this.$store.getters.modalStatus
    }
  },

  watch: {
    getPageStatus: function () {
      this.pageStatus = this.$store.getters.pageStatus
    },
    getModalStatus: function(status) {
        this.modalStatus = status
    }
  },

  methods: {
    openPage() {
      this.$store.commit('updatePage', 'page-open')
    }
  }
}
</script>